import { forwardRef } from 'react';
import style from './SwiperArrow.module.scss'

interface SwiperArrowProps {
  className?: string,
  direction?: 'left' | 'right'
}

const SwiperArrow = forwardRef<HTMLButtonElement, SwiperArrowProps>(({ className, direction = 'right' }, ref) => {
  return <button type='button'
    ref={ref}
    className={`${style.button}${className ? ' ' + className : ''}`}
    aria-label={`${direction === 'right' ? 'Следующий' : 'Предыдущий'} слайд`}
    data-dir={direction}
  >
    <div className={style.arrow} />
  </button>
})

export default SwiperArrow;