import Container from '../../../UI/Container/Container';
import style from './OurCompany.module.scss'
import OurCompanySlider from "./OurCompanySlider/OurCompanySlider";

const OurCompany: React.FC = () => {
  return (
    <Container>
      <h2 className={style.titleMobile}>Наша компания</h2>
      <div className={style.content}>
        <div className={style.description}>
          <h2 className={style.title}>Наша компания</h2>
          <p><strong>Медиахолдинг Ассоциации «Галерея Чижова»</strong> – один из крупнейших рекламных холдингов Воронежа!</p>
          <p><strong>Медиахолдинг Ассоциации «Галерея Чижова»</strong> – это команда профессионалов с опытом работы в сфере рекламы более 20 лет!</p>
          <p><strong>Медиахолдинг Ассоциации «Галерея Чижова»</strong> – это рекламные возможности с общим охватом аудитории более 1 000 000 человек!</p>
          <p><strong>Все описанные носители являются собственностью холдинга.</strong></p>
          {/* <p><strong>Поэтому только у нас эксклюзивные условия размещения на всех носителях.</strong></p>
          <p><strong>Гибкая система скидок позволит провести экономичную и эффективную рекламную кампанию!</strong></p>
          <p><strong>Не бойтесь ставить задачи – мы всегда сможем найти решение, приемлемое для Вас по стоимости и по эффективности!</strong></p>
          <h3><strong>РЕКЛАМНЫЕ ВОЗМОЖНОСТИ:</strong></h3>
          <p>1. Размещение рекламы в ТЦ Галерея Чижова</p>
          <p>2. Реклама на радио: LOVERadio, Радио MAXIMUM, Радио Дача и RELAXFM</p>
          <p>3. Собственная студия звукозаписи: изготовление аудиорекламы с использованием голосов актеров Воронежа и Санкт-Петербурга</p>
          <p>4. Реклама в городском еженедельнике полезной информации «Галерея Чижова» и на сайте infovonezh.ru</p>
          <p>5. Рекламные возможности ЦЕНТРА ГАЛЕРЕИ ЧИЖОВА и фитнес-клуба Worldclass</p> */}
        </div>
        <OurCompanySlider />
      </div>
    </Container>
  )
}

export default OurCompany;