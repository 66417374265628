import style from './FormButtons.module.scss'
import useModal from "../../../providers/ModalProvider/useModal";
import { classNames } from '../../../utils/classNames/classNames';
import { Link } from 'react-router-dom';
import pdfFile from '../../../assets/files/price-list_2023.pdf'
import pdfIcon from '../../../assets/image/pdf.svg'

interface FormButtonsProps {
  className?: string
}

const FormButtons: React.FC<FormButtonsProps> = ({ className }) => {
  const { openCall } = useModal()

  return <div className={classNames(style.wrapper, {}, [className ?? ''])}>
    <Link to={pdfFile}
      target='_blanc'
      className={classNames(style.button, {}, [style.buttonFirst])}>
      Прайс-лист
      <img src={pdfIcon} alt="PDF" width={30} height={30} />
    </Link>
    <button
      type="button"
      className={classNames(style.button, {}, [style.buttonSecond])}
      onClick={openCall}>
      Заказать звонок
    </button>
  </div >
}

export default FormButtons;