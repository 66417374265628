import {useCallback, useEffect, useState} from "react";
import style from './ModalCall.module.scss'
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';
import {sendForm} from "../../../utils/api";
import {useForm} from 'react-hook-form'
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import CloseModalButton from "../../UI/CloseModalButton/CloseModalButton";
import CheckBox from "../../UI/CheckBox/CheckBox";
import Input from "../../UI/Input/Input";
import Popup from "../Popup/Popup";
import {ICall, SendingStatus} from "../../../types/types";
import ModalFormSucces from "../ModalFormSucces/ModalFormSucces";
import useModal from "../../../providers/ModalProvider/useModal";
import Spinner from "../Spinner/Spinner";

const ModalCall: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: {errors, touchedFields},
    reset
  } = useForm<ICall>({mode: 'onBlur'});

  const {modalActive, closeModal} = useModal()

  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);

  const [token, setToken] = useState<string>();
  const [status, setStatus] = useState<SendingStatus>(null)
  const [isSending, setSending] = useState(false)
  const [agreement, setAgreement] = useState(false)

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  const onSubmit = async (data: ICall) => {
    try {
      setSending(true)
      setStatus(null)
      const response = await sendForm("call", data, token ?? '');
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
    } catch (e) {
      setStatus('error')
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  if (status === 'success') {
    return <Popup active={modalActive === "call"} closeModal={closeModal}>
      <ModalFormSucces/>
    </Popup>
  }

  return <Popup active={modalActive === "call"} closeModal={closeModal}>
    <div className={style.wrapper}>
      <CloseModalButton className={style.closeBtn} onClick={closeModal}/>
      <h2 className={style.title}>Оставьте<br/>ваш номер</h2>
      <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
        <Input id="call__name"
               error={errors.name}
               touched={touchedFields.name}
               {...register('name', {
                 required: 'Необходимо заполнить «Имя».'
               })} placeholder="Имя">Имя</Input>

        <Input id="call__phone" error={errors.phone}
               touched={touchedFields.phone}
               type="tel"
               mask="+7(999)999-99-99"
               {...register('phone', {
                 required: 'Необходимо заполнить «Телефон».',
                 pattern: {
                   value: /^[^_]*$/,
                   message: 'Необходимо заполнить «Телефон».'
                 }
               })} placeholder="Телефон">Телефон</Input>

        <CheckBox id='call__agreement'
                  checked={agreement}
                  onChange={e => setAgreement(e.target.checked)}
                  required>
          Ознакомлен и даю согласие на обработку персональных данных
        </CheckBox>

        <GoogleReCaptcha
          action='homepage'
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />

        {status === 'error' &&
            <p className={style.error}>Произошла ошибка при отправке формы. Попробуйте перезагрузить страницу или
                отправить форму позже снова</p>
        }

        <SubmitButton
          className={style.submit}
          disabled={isSending}
        >
          {isSending ?
            <>
              Отправка <Spinner/>
            </>
            : 'Отправить'}
        </SubmitButton>
      </form>
    </div>
  </Popup>
}
export default ModalCall;
