import { forwardRef } from 'react';
import style from './Select.module.scss'
import { FieldError } from 'react-hook-form';

interface SelectProps {
  error?: FieldError,
  touched?: boolean | undefined,
  children: React.ReactNode,
  id: string,
  label: string,
  className?: string
}

const Select: React.FC<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(({ error, label, touched, children, id, className, ...props }, ref) => {
  return <div className={style.wrapper}
    data-status={error ? 'error' : touched ? 'succes' : ''}>
    <label className={style.label} htmlFor={id}>
      {label}
    </label>
    <select id={id} className={`${style.select}${className ? ' ' + className : ''}`} {...props} ref={ref}>
      {children}
    </select>
    {error?.message && <p className={style.helpBlock}>{error?.message}</p>}
  </div>
})

export default Select;