import { useState, useCallback, useEffect } from "react";
import style from './ModalBriff.module.scss'
import rangeArray from "../../../utils/rangeArray/rangeArray";
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { sendForm } from "../../../utils/api";
import { roundValue } from "../../../utils/rangeArray/rangeArray";
import { useForm } from "react-hook-form";
import Popup from "../Popup/Popup";
import { IBriff, SendingStatus } from "../../../types/types";
import CloseModalButton from "../../UI/CloseModalButton/CloseModalButton";
import Input from "../../UI/Input/Input";
import CheckBox from "../../UI/CheckBox/CheckBox";
import Select from "../../UI/Select/Select";
import SubmitButton from "../../UI/SubmitButton/SubmitButton";
import ReactSlider from 'react-slider';
import ModalFormSucces from "../ModalFormSucces/ModalFormSucces";
import useModal from "../../../providers/ModalProvider/useModal";
import { classNames } from "../../../utils/classNames/classNames";
import Spinner from "../Spinner/Spinner";

const ModalBriff: React.FC = () => {
  const { register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
    getValues,
    setError,
    setValue,
    clearErrors
  } = useForm<IBriff>({ mode: 'onBlur', defaultValues: { budget: '1000' } });
  const [agreement, setAgreement] = useState(false)

  const { closeModal, modalActive } = useModal();

  const { onBlur: onblurBudget } = register('budget')

  const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
  const [token, setToken] = useState<string>();
  const [currentStep, setCurrentStep] = useState(0)

  const [status, setStatus] = useState<SendingStatus>(null)
  const [isSending, setSending] = useState(false)

  const [place, setPlace] = useState([
    { name: 'Радио', status: false },
    { name: 'Рекламные щиты', status: false },
    { name: 'Реклама в Центре Галереи Чижова', status: false },
    { name: 'Реклама в газете Галереи Чижова', status: false },
    { name: 'Запись аудиорекламы', status: false }
  ])
  const [budget, setBudget] = useState(1000);

  const onVerify = useCallback((token: string) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000)
    return () => clearInterval(id)
  }, [])

  const roundRange = () => {
    setValue('budget', String(roundValue(getValues('budget'))));
    setBudget(roundValue(getValues('budget')))
  }

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const idx = place.findIndex(el => el.name === e.target.value)
    const newPlaceArr = [...place.slice(0, idx),
    { name: e.target.value, status: e.target.checked },
    ...place.slice(idx + 1)];
    setPlace(newPlaceArr);
    const placeString = newPlaceArr.filter(el => el.status).map(el => el.name).join(', ')
    setValue('map', placeString)
    if (placeString.length) {
      clearErrors('map')
    }
    else setError('map', { type: 'custom', message: 'Необходимо заполнить место размещения рекламы' })
  }

  const onSubmit = async (data: IBriff) => {
    try {
      setSending(true)
      setStatus(null)
      const response = await sendForm('briff', data, token);
      if (!response.ok) {
        throw new Error();
      }
      setRefreshReCaptcha(r => !r);
      reset();
      setStatus('success');
      setBudget(1000);
    }
    catch (e) {
      setStatus('error')
    }
    finally {
      setSending(false)
    }
  }

  if (status === 'success') {
    return <Popup active={modalActive === "briff"} closeModal={closeModal}>
      <ModalFormSucces />
    </Popup>
  }

  return <Popup active={modalActive === "briff"} closeModal={closeModal}>
    <div className={style.wrapper}>
      <CloseModalButton className={style.closeBtn} onClick={closeModal} />
      <h2 className={style.title}>Заполните бриф</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classNames(style.step, { [style.active]: currentStep === 0 })}>
          <div className={`${style.stepProgress}`}>Шаг 01 <span></span></div>
          <h3 className={style.categoryTitle}>Расскажите о вашей компании</h3>
          <div className={style.company}>
            <Input id="briff__company" error={errors.company} touched={touchedFields.company}
              {...register('company', {
                required: 'Необходимо заполнить «Название компании».'
              })} placeholder='Ассоциация "Галерея Чижова"'>Название компании</Input>

            <Select id='briff__product' error={errors.category}
              touched={touchedFields.category}
              label="Сфера деятельности"
              className={style.formControl}
              {...register('category', {
                required: 'Выберите один из предложенных вариантов.'
              })}>
              <option value="">Сфера деятельности</option>
              <option value="Дизайн">Дизайн</option>
              <option value="Мода">Мода</option>
              <option value="Интерьер">Интерьер</option>
              <option value="Транспорт">Транспорт</option>
              <option value="Медицина">Медицина</option>
              <option value="Образование">Образование</option>
              <option value="Недвижимость">Недвижимость</option>
              <option value="Развлечения">Развлечения</option>
              <option value="Общественное питание">Общественное питание</option>
              <option value="Финансы">Финансы</option>
              <option value="Строительство">Строительство</option>
              <option value="Бизнес">Бизнес</option>
              <option value="Реклама">Реклама</option>
              <option value="Производство">Производство</option>
              <option value="Другое">Другое</option>
            </Select>

            <Input id="briff__site" error={errors.site} touched={touchedFields.site} {...register('site', {
              required: false
            })}>Адрес вашего сайта</Input>
            <p className={style.productNotice}>Или ссылка на соцсети вашей компании</p>
          </div>

          <button type="button" className={style.nextBtn} onClick={() => {
            let valid = true;
            if (!getValues('company').length) {
              setError('company', { type: 'required', message: 'Необходимо заполнить «Название компании».' })
              valid = false;
            }
            if (!getValues('category').length) {
              setError('category', { type: 'required', message: 'Выберите один из предложенных вариантов.' })
              valid = false;
            }
            if (valid) {
              setCurrentStep(1)
            }
          }}>Продолжить заполнение</button>
        </div>

        <div className={classNames(style.step, { [style.active]: currentStep === 1 })}>
          <div className={`${style.stepProgress} ${style.stepProgressTwo}`}>Шаг 02 <span></span></div>
          <h3 className={style.categoryTitle}>Укажите место размещения вашей рекламы</h3>
          <div className={style.placeWrapper}>
            <div className={style.place}>
              <input type="text" className={style.placeInput}
                {...register('map', {
                  required: true
                })} />
              {place.map(({ name, status }, index) => {
                return (
                  <CheckBox checked={status}
                    key={index}
                    onChange={onChecked}
                    value={name}
                    id={`briff__${name}`}>
                    {name}
                  </CheckBox>
                )
              })}
            </div>
            {errors.map && <p className={style.helpBlock}>Необходимо заполнить место размещения рекламы</p>}
          </div>

          <h3 className={style.categoryTitle}>Укажите период проведения рекламной кампании</h3>
          <div className={style.date}>
            <Input id="briff__begin_date" sizeType="small"
              className={style.datepicker}
              error={errors.begin_date}
              touched={touchedFields.begin_date}
              {...register('begin_date', {
                required: false
              })} type="date" />

            <span>—</span>

            <Input id="briff__end_date" sizeType="small"
              className={style.datepicker}
              error={errors.end_date}
              touched={touchedFields.end_date} {...register('end_date', {
                required: false
              })} type="date" />
          </div>

          <h3 className={style.categoryTitle}>Укажите планируемый бюджет</h3>
          <div className={style.moneyWrapper}>
            <Input id="briff__budget" className={style.budget} error={errors.budget}
              sizeType="small"
              touched={touchedFields.budget}
              {...register('budget', {
                required: false
              })} onBlur={(e) => {
                onblurBudget(e);
                roundRange();
              }} />
            <ReactSlider
              className={style.slider}
              thumbClassName={style.sliderThumb}
              trackClassName={style.sliderTrack}
              defaultValue={rangeArray.indexOf(budget)}
              value={rangeArray.indexOf(budget)}
              min={0}
              max={rangeArray.length - 1}
              onChange={value => {
                setValue('budget', String(rangeArray[value]));
                setBudget(rangeArray[value])
              }}
            />
          </div>

          <button type="button" className={style.nextBtn} onClick={() => {
            let valid = true;
            if (!getValues('map').length) {
              setError('map', { type: 'required', message: 'Необходимо заполнить место размещения рекламы.' })
              valid = false;
            }
            if (valid) {
              setCurrentStep(2)
            }
          }}>Продолжить заполнение</button>

          <button type="button" className={style.prevBtn} onClick={() => {
            setCurrentStep(0)
          }}>Вернуться назад</button>

        </div>

        <div className={classNames(style.step, { [style.active]: currentStep === 2 })}>
          <div className={`${style.stepProgress} ${style.stepProgressTwo}`}>Шаг 03 <span></span></div>
          <h3 className={style.categoryTitle}>Расскажите о себе</h3>
          <div className={style.inputGroup}>
            <Input id="briff__name" error={errors.name} touched={touchedFields.name} {...register('name', {
              required: 'Необходимо заполнить «Имя».',
            })} placeholder="Имя">Имя</Input>

            <Input id="briff__prof" error={errors.prof} touched={touchedFields.prof} {...register('prof', {
              required: 'Необходимо заполнить «Должность».',
            })} placeholder="Должность">Должность</Input>
          </div>

          <div className={style.inputGroup}>
            <Input id="briff__phone" error={errors.phone} touched={touchedFields.phone} mask="+7(999)999-99-99" type="tel" {...register('phone', {
              required: 'Необходимо заполнить «Телефон».',
              pattern: {
                value: /^[^_]*$/,
                message: 'Необходимо заполнить «Телефон».'
              }
            })} placeholder="Телефон">Телефон</Input>

            <Input id="briff__email" error={errors.email} touched={touchedFields.email} type="email" {...register('email', {
              required: 'Необходимо заполнить «Email».',
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Значение «E-mail» не является правильным email адресом.'
              }
            })} placeholder="example@example.ru">E-mail</Input>
          </div>

          <div className={style.agreement}>
            <CheckBox id='briff__agreement' checked={agreement} onChange={e => setAgreement(e.target.checked)} required>
              Ознакомлен и даю согласие на обработку персональных данных
            </CheckBox>
          </div>

          <GoogleReCaptcha
            action='homepage'
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />

          {status === 'error' &&
            <p className={style.error}>Произошла ошибка при отправке формы. Попробуйте перезагрузить страницу или отправить форму позже снова</p>
          }

          <SubmitButton type="submit"
            className={style.submitBtn}
            disabled={isSending}
          >
            {isSending ?
              <>
                Отправка <Spinner />
              </>
              : 'Отправить'}
          </SubmitButton>
          <button type="button" className={style.prevBtn} onClick={() => {
            setCurrentStep(1)
          }}>Вернуться назад</button>
        </div>
      </form>
    </div>
  </Popup>
}
export default ModalBriff;
