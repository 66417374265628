const rangeArray: number[] = [];
const min = 1000;
const max = 10000000;

let i = min;

while (i <= max) {
  if (i < 15000) {
    rangeArray.push(i);
    i += 100;
  }
  else if (i < 25000) {
    rangeArray.push(i);
    i += 500;
  }
  else if (i < 50000) {
    rangeArray.push(i);
    i += 1000;
  }
  else if (i < 100000) {
    rangeArray.push(i);
    i += 5000;
  }
  else if (i < 1000000) {
    rangeArray.push(i);
    i += 10000;
  }
  else if (i <= 10000000) {
    rangeArray.push(i);
    i += 100000;
  }
}

export const roundValue = (initial: number | string): number => {
  let value = +(initial);
  if (isNaN(value)) {
    return value = 1000;
  }
  else if (value < 1000) {
    return value = 1000;
  }
  else if (value >= 1000 && value <= 15000) {
    return value = Math.round(value / 100) * 100;
  }
  else if (value > 15000 && value <= 25000) {
    return value = Math.round(value / 500) * 500;
  }
  else if (value > 25000 && value <= 50000) {
    return value = Math.round(value / 1000) * 1000;
  }
  else if (value > 50000 && value <= 100000) {
    return value = Math.round(value / 5000) * 5000;
  }
  else if (value > 100000 && value <= 1000000) {
    return value = Math.round(value / 10000) * 10000;
  }
  else if (value > 1000000 && value <= 10000000) {
    return value = Math.round(value / 100000) * 100000;
  }
  else return value = 10000000
}

export default rangeArray;