import { useEffect } from 'react';
import style from './NotFoundPage.module.scss'
import { Link } from 'react-router-dom'
import { setDocumentTitle } from '../../../utils/setDocumentTitle/setDocumentTitle';

const NotFoundPage: React.FC = () => {
  useEffect(() => {
    setDocumentTitle("Страница не найдена");
  }, [])
  return <div className={style.wrapper}>
    <div className={style.textCircleWrapper}>
      <svg viewBox="0 0 430 440" className={style.circleSvg}>
        <path d="M 50 220 C 46 135 102 51 215 50 C 320 54 380 123 380 220 C 385 304 321 385 215 390 C 109 391 42 296 50 223 Z" fill="#fff" fill-opacity="0" id="textcircle"></path>
        <text className={style.textCircle} dy="10" fill="#e22f9a">
          <textPath href="#textcircle" startOffset="0" fill="#e22f9a">нам по телефону <tspan>261-99-99</tspan> &emsp; Попробуйте вернуться <Link className={style.circleLink} to="/">на главную</Link> &emsp;  Также можете сообщить</textPath>
        </text>
      </svg>
      <Link to='/' className={style.logoLink}>
        <h2 className={style.linkTitle}>Медиахолдинг</h2>
        <p className={style.linkText}>Ассоциации «Галерея Чижова»</p>
      </Link>
    </div>
    <div className={style.cassette}/>
  </div>
}

export default NotFoundPage;