import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClientProvider, QueryClient } from 'react-query'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const client = new QueryClient()

root.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <GoogleReCaptchaProvider reCaptchaKey='6LdzC9gZAAAAAJ3w44mpEbO8q6zVffbNqhxVVCCN'>
        <App />
      </GoogleReCaptchaProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
