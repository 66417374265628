import style from './CloseModalButton.module.scss'

interface CloseModalButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {

}

const CloseModalButton: React.FC<CloseModalButtonProps> = ({ className, type = 'button', ...props }) => {
  return <button
    className={`${style.button}${className ? ' ' + className : ''}`}
    type={type}
    aria-label='Закрыть модальное окно'
    {...props} />
}

export default CloseModalButton;