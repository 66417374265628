import { useEffect, useState } from "react";
import style from "./ActiveHeader.module.scss"
import { Link } from "react-router-dom";
import Container from "../../UI/Container/Container";
import useModal from "../../../providers/ModalProvider/useModal";
import { classNames } from "../../../utils/classNames/classNames";

const root = document.getElementById('root') as HTMLElement

const ActiveHeader: React.FC = () => {
  const [scroll, setScroll] = useState(0);

  const { openBriff, openCall } = useModal();

  const handleScroll = () => {
    setScroll(root?.scrollTop);
  }

  useEffect(() => {
    root?.addEventListener("scroll", handleScroll);
    return () => root?.removeEventListener("scroll", handleScroll)
  }, []);

  return (
    <div className={classNames(style.activeHeader, { [style.hidden]: scroll < 99 })}>
      <Container className={style.innerBlock}>
        <Link to='/' className={style.homeLinkWrapper}>
          <h2 className={style.homeLinkTitle}>Медиахолдинг</h2>
          <p className={style.homeLinkText}>Ассоциации «Галерея Чижова»</p>
        </Link>
        <div className={style.buttons}>
          <button type="button"
            className={style.brief}
            onClick={openBriff}>
            Заполнить бриф
          </button>
          <button
            type="button"
            className={style.call}
            onClick={openCall}>
            Заказать звонок
          </button>
          <a href="tel:+74732619995" className={style.phone}>+7 (473) 261-99-95</a>
        </div>
      </Container>
    </div >
  )
}

export default ActiveHeader;