export const BASE_URL = 'https://chg-mall.ru/'

type formPath = 'briff' | 'call' | 'sub'

export const sendForm = async<FormType>(path: formPath, data: FormType, token?: string) => {
  let formData = new FormData();
  for (let item in data) {
    formData.append(item, String(data[item as keyof FormType]))
  }
  token && formData.set('reCaptcha', token);
  const response = await fetch(`${BASE_URL}api/media/${path}/create`, {
    body: formData,
    method: 'post'
  })
  return response;
}