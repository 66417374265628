import style from './Container.module.scss'

interface ContainerProps {
  className?: string,
  children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children, className }) => {
  return <div className={`${style.container}${className ? ' ' + className : ''}`}>
    {children}
  </div>
}

export default Container;