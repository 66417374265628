import {useEffect} from "react";
import style from './PricePage.module.scss'
import FormButtons from '../../share/FormButtons/FormButtons'
import Container from '../../UI/Container/Container'
import Title from '../../UI/Title/Title'
import {setDocumentTitle} from "../../../utils/setDocumentTitle/setDocumentTitle";

const PricePage: React.FC = () => {
  useEffect(() => {
    setDocumentTitle("Цены");
  }, [])

  return (
    <Container className={style.main}>
      <Title>Прайс-лист</Title>
      <div className={style.infoContentBlock}>
        <div className={style.infoText}>
          <p><strong>Публикации (сайт соц.сети):</strong></p>
          <p>Публикация на сайте ЦГЧ с маркировкой рекламы (20+ тыс. посетителей в мес.) 3000 руб. </p>
          <p>Пост ВК и в Телеграмм ЦГЧ с маркировкой рекламы (20+ тыс. подписчиков) 4000 руб. </p>
          <p>Публикация на сайте ЦГЧ, а также в ВКонтакте и Телеграмм - при маркировке рекламы самим заказчиком 5000
            руб. </p>
        </div>
        <div className={style.infoText}>
          <p><strong>Интернет-реклама с продвижением:</strong></p>
          <p>Пост с маркировкой рекламы и дополнительным рекламным продвижением в Вконтакте (увеличение охвата
            публикации до 50 тыс. просмотров потенциальной ЦА) 24000 руб.</p>
          <p>Публикация на сайте ЦГЧ с маркировкой рекламы и рекламным продвижением в Яндекс 23000 руб. (увеличение
            охвата публикации до 150 тыс. просмотров рекламного объявления потенциальной ЦА)</p>
        </div>
        <div className={style.infoText}>
          <p><strong>Страница магазина на сайте ЦГЧ:</strong></p>
          <p>Создание расширенной страницы магазина в рамках структуры и шаблона сайта ЦГЧ,
            для повышения вовлеченности потенциальных клиентов
            пример https://shoppingcenter-gallery-chizhov.ru/shop/gerry-weber
            (контент - текст, фото, видео предоставляет заказчик) 10 000 руб.
          </p>
          <p>Обслуживание страницы - 1 пакет правок
            (изменение/замена изображений до 10 шт., корректировка/замена текста до 2 тыс. символов)
            2000 руб.
          </p>
        </div>
        <div className={style.infoText}>
          <p><strong>Главный (имиджевый) банер на сайте ЦГЧ:</strong></p>
          <p>Размещение банера на главной странице сайта с ссылкой на расширенную информацию в раздел "Акции". Период 1
            месяц 10 000 руб.
          </p>
        </div>
        <div className={style.infoText}>
          <p><strong>Публикации с маркировкой рекламы оплачиваются за период до 30 дней</strong></p>
        </div>
      </div>
      <FormButtons/>
    </Container>
  )
}

export default PricePage;
