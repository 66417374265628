import ScrollToTop from "../../utils/scrollToTop_HOC";
import ActiveHeader from "./ActiveHeader/ActiveHeader";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import style from './Layout.module.scss'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <ScrollToTop>
      {window.matchMedia('(min-width:769px)').matches && <ActiveHeader />}
      <Header />
      <main className={style.main}>
        {children}
      </main>
      <Footer />
    </ScrollToTop>
  )
}

export default Layout;