import './swiper.css'
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import NotFoundPage from './components/pages/NotFoundPage/NotFoundPage';
import HomePage from './components/pages/HomePage/HomePage'
import ModalBriff from './components/share/ModalBriff/ModalBriff';
import ModalCall from './components/share/ModalCall/ModalCall';
import ModalProvider from './providers/ModalProvider/ModalProvider';
import { Suspense, lazy } from 'react';
import PageSkeleton from './components/share/PageSkeleton/PageSkeleton';
import PricePage from './components/pages/PricePage/PricePage';

const AdvertiserPage = lazy(() => import('./components/pages/AdvertiserPage/AdvertiserPage'));
const AdsPage = lazy(() => import('./components/pages/AdsPage/AdsPage'));
const ContactsPage = lazy(() => import('./components/pages/ContactsPage/ContactsPage'));
const AboutPage = lazy(() => import('./components/pages/AboutPage/AboutPage'));

const App: React.FC = () => {

  return (
    <ModalProvider>
      <ModalBriff />
      <ModalCall />
      <Layout>
        <Suspense fallback={<PageSkeleton />}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path='advertiser' element={<AdvertiserPage />} />
            <Route path='about' element={<AboutPage />} />
            <Route path='price' element={<PricePage />} />
            <Route path='contacts' element={<ContactsPage />} />
            <Route path='offers/:article' element={<AdsPage path="offers" />} />
            <Route path='reklama/:article' element={<AdsPage path="ads" />} />
            <Route path='*' element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </Layout>
    </ModalProvider>
  )
}

export default App;
