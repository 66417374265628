import style from './Header.module.scss'
import logo from '../../../assets/image/logo.png'
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from 'react';
import Container from '../../UI/Container/Container'
import MobileMenu from './MobileMenu/MobileMenu';
import { classNames } from '../../../utils/classNames/classNames';
import { linkType } from '../../../types/types';

const root = document.getElementById('root') as HTMLElement

const Header: React.FC = () => {
  const [scroll, setScroll] = useState(0);
  const [mobileActive, setMobileActive] = useState(false);

  const links: linkType[] = [
    { name: 'Рекламодателям', path: '/advertiser' },
    { name: 'О нас', path: '/about' },
    { name: 'Контакты', path: '/contacts' }
  ]

  const handleScroll = () => {
    setScroll(root?.scrollTop);
  }

  useEffect(() => {
    root?.addEventListener("scroll", handleScroll);
    return () => root?.removeEventListener('scroll', handleScroll)
  }, []);

  const toggleMobileMenu = () => setMobileActive(pr => !pr)

  const closeMobileMenu = () => setMobileActive(false)

  return (<>
    {
      window.matchMedia('(max-width: 992px)').matches &&
      <MobileMenu active={mobileActive}
        onClose={closeMobileMenu}
        links={[{ name: 'Главная', path: '/' }, ...links]} />
    }

    <button type="button" className={classNames(style.burger, { [style.active]: mobileActive })}
      aria-label='Открыть боковое меню'
      onClick={toggleMobileMenu}>
      <span className={style.burgerSpan} />
    </button>
    <header className={classNames(style.wrapper, { [style.scrolled]: scroll > 99 })}>
      <Container>
        <nav className={style.navContainer}>
          <Link to='/' className={style.homeLink}>
            <p className={style.homeLinkTitle}>Медиахолдинг</p>
            Ассоциации «Галерея Чижова»
          </Link>
          <ul className={style.navigation}>
            {links.map(({ name, path }) => {
              return <li className={style.linkWrapper}
                key={name}>
                <NavLink to={path}
                  className={({ isActive }) => classNames(style.link, { [style.activeLink]: isActive })}>
                  {name}
                </NavLink>
              </li>
            })}
          </ul>
          <a href="tel:+74732619995" className={style.phone}>+7 (473) 261-99-95</a>
          <img src={logo} alt="Логотип" className={style.logo} width={80} height={125} />
        </nav>
      </Container>
    </header >
  </>
  )
}

export default Header;