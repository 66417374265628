import Container from '../../UI/Container/Container';
import SkeletonPlaceholder from '../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './PageSkeleton.module.scss'

const PageSkeleton: React.FC = () => {
  return <Container>
    <div className={style.titleContainer}>
      <SkeletonPlaceholder className={style.title} />
    </div>
    <SkeletonPlaceholder className={style.text} />
    <SkeletonPlaceholder className={style.text} />
  </Container>
}

export default PageSkeleton;