import { Link } from 'react-router-dom';
import style from './HomeAdItem.module.scss'

interface HomeAdItemProps {
  counter: string,
  title: string,
  slug: string,
  audi: string,
  short: string
}

const HomeAdItem: React.FC<HomeAdItemProps> = (props) => {
  const {
    counter,
    title,
    slug,
    audi,
    short
  } = props;

  return <div className={style.container}>
    <p className={style.counter}>{counter}</p>
    <div className={style.main}>
      <p className={style.title}>{title}</p>
      <p className={style.short}>{short}</p>
      <div className={style.audience} dangerouslySetInnerHTML={{ __html: audi }} />
      <Link className={style.link} to={`/reklama/${slug}`}>Узнать условия</Link>
    </div>
  </div>
}

export default HomeAdItem;