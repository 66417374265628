import style from './SubmitButton.module.scss'

interface SubmitButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ children, className, type = 'submit', ...props }) => {
  return <button
    className={`${style.button}${className ? ' ' + className : ''}`}
    type={type}
    {...props}>
    {children}
  </button>
}

export default SubmitButton;