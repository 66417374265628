import { classNames } from '../../../utils/classNames/classNames';
import Portal from '../Portal/Portal';
import style from './Popup.module.scss'

interface PopupProps {
  children: React.ReactNode,
  active: boolean,
  closeModal: () => void,
}

const Popup: React.FC<PopupProps> = ({ active, children, closeModal }) => {
  return <Portal>
    <div className={classNames(style.overlay, { [style.active]: active })} onClick={closeModal} tabIndex={0}>
      <div className={style.content} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  </Portal>
}

export default Popup;