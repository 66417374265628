import { useQuery } from 'react-query';
import { AdsType } from '../../../../types/types';
import style from './HomeAdBlock.module.scss'
import { BASE_URL } from '../../../../utils/api';
import HomeAdPlaceholder from './HomeAdItem/HomeAdPlaceholder';
import HomeAdItem from './HomeAdItem/HomeAdItem';
import Container from '../../../UI/Container/Container';

const HomeAdBlock: React.FC = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['adSlider'],
    queryFn: (): Promise<AdsType[]> => fetch(`${BASE_URL}api/media/ads`)
      .then(res => res.json()).then(res => res.models)
  })

  return <Container className={style.homeAdBlock}>
    {isLoading ? <>
      <HomeAdPlaceholder />
      <HomeAdPlaceholder />
    </>
      : data?.map(item => <HomeAdItem key={item.slug} {...item} />)
    }
  </Container>
}

export default HomeAdBlock;